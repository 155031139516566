import { Dispatch, SetStateAction, useState } from 'react';

import { useSearchParams } from 'next/navigation';

import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { MenuItem, Popover, Stack, Typography } from '@mui/material';

import { FlexBox } from 'libs/components/flex-box';

interface Props {
  setCategory: Dispatch<SetStateAction<string | undefined>>;
}

function SearchDropDown({ setCategory }: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const params = useSearchParams();

  const [value, setValue] = useState(
    params.get('category')?.replaceAll('-', ' ')?.replaceAll('templates', '') ||
      'All categories'
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const categories = [
    {
      key: 'All categories',
      value: 'All Categories',
    },
    {
      key: 'After effect',
      value: 'after-effects-templates',
    },
    {
      key: 'Premiere pro',
      value: 'premiere-pro-templates',
    },
    {
      key: 'Graphics design',
      value: 'images',
    },
    {
      key: 'Motion graphics',
      value: 'videos',
    },
  ];

  return (
    <div>
      <FlexBox
        alignItems={'center'}
        justifyContent={'center'}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          background: '#FFF',
          height: '2rem',
          width: 'max-content',
          borderRight: '1px solid #d5d5d5',
          borderTopLeftRadius: '3rem',
          borderBottomLeftRadius: '3rem',
        }}
      >
        <Typography
          color={'black'}
          fontSize={{ xs: '0.6rem', sm: '0.75rem' }}
          padding={1}
          textTransform={'capitalize'}
        >
          {value}
        </Typography>
        <KeyboardArrowDownOutlined sx={{ color: '#888888' }} />
      </FlexBox>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableScrollLock={true}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginTop: '0.5rem' }}
      >
        <Stack direction="column" p={1}>
          {categories?.map((item) => (
            <MenuItem
              key={item.key}
              style={{ marginTop: 0 }}
              onClick={() => {
                setCategory(item.value);
                setValue(item.key);
                handleClose();
              }}
            >
              <FlexBox gap={'0.5rem'} alignItems={'center'}>
                {item.key}
              </FlexBox>
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </div>
  );
}

export default SearchDropDown;
