const afterEffectTempalteURL = 'after-effects-templates';
const premierProjectsURL = 'premiere-pro-templates';
const illustrations = 'illustrations';
const motionGraphics = 'videos';
const psdProjectURL = 'psd-templates';
const graphicsDesign = 'images';

export const navLinks = [
  {
    title: 'Templates',
    path: afterEffectTempalteURL,
    childrens: [
      {
        title: 'After Effect',
        path: afterEffectTempalteURL,
        child: [
          { title: 'Slideshows', url: `/${afterEffectTempalteURL}/slideshow` },
          {
            title: 'Social Media',
            url: `/${afterEffectTempalteURL}/social-media`,
          },
          {
            title: 'Logo Animations',
            url: `/${afterEffectTempalteURL}/logo-animation`,
          },
          {
            title: 'Opener & Intro',
            url: `/${afterEffectTempalteURL}/Opener-&-Intro`,
          },
          {
            title: 'Titles',
            url: `/${afterEffectTempalteURL}/titles`,
          },
          {
            title: 'Elements',
            url: `/${afterEffectTempalteURL}/elements`,
          },
          {
            title: 'Invitation & Wishes',
            url: `/${afterEffectTempalteURL}/invitation-and-wishes`,
          },
        ],
      },
      {
        title: 'Premiere Pro',
        path: premierProjectsURL,
        child: [
          {
            title: 'Festival',
            url: `/${premierProjectsURL}/festival`,
          },
          { title: 'Intros', url: `/${premierProjectsURL}/intros` },
          {
            title: 'Invitation',
            url: `/${premierProjectsURL}/invitation-and-greeting`,
          },
          {
            title: 'Logo Reveal',
            url: `/${premierProjectsURL}/logo-animation`,
          },
          {
            title: 'Text And Title',
            url: `/${premierProjectsURL}/text-and-title`,
          },
          {
            title: 'Video Slideshow',
            url: `/${premierProjectsURL}/video-display-slideshow`,
          },
          { title: 'Clean', url: `/${premierProjectsURL}/clean` },
        ],
      },
    ],
  },
  {
    title: 'Graphics',
    path: `${graphicsDesign}`,
    childrens: [
      {
        title: 'PSD',
        path: `psd-templates`,
        child: [
          { title: 'Festive', url: `/${psdProjectURL}/festive` },
          { title: 'Celebration', url: `/${psdProjectURL}/celebration` },
          { title: 'Banners', url: `/${psdProjectURL}/banner` },
          { title: 'Artistic', url: `/${psdProjectURL}/artistic` },
          { title: 'Social Media', url: `/${psdProjectURL}/social-media` },
          { title: 'Sale Events', url: `/${psdProjectURL}/sale` },
        ],
      },
      {
        title: 'Designs',
        path: graphicsDesign,
        child: [
          { title: '3D', url: `/${graphicsDesign}/3d` },
          { title: '2D', url: `/${graphicsDesign}/2d` },
          { title: 'Vector', url: `/${graphicsDesign}/vector` },
          { title: 'Woman', url: `/${graphicsDesign}/woman` },
          { title: 'Business', url: `/${graphicsDesign}/business` },
          { title: 'Corporate', url: `/${graphicsDesign}/corporate` },
          { title: 'Growth', url: `/${graphicsDesign}/growth` },
          { title: 'Promo', url: `/${graphicsDesign}/promo` },
          { title: 'Investment', url: `/${graphicsDesign}/investment` },
        ],
      },
      {
        title: 'Illustartions',
        path: illustrations,
        child: [
          { title: 'Art', url: `/${illustrations}/art` },
          { title: 'Happy', url: `/${illustrations}/happy` },
          { title: 'Coding', url: `/${illustrations}/coding` },
          { title: 'Sports', url: `/${illustrations}/finance` },
          { title: 'Elegant', url: `/${illustrations}/elegant` },
          { title: 'Costume', url: `/${illustrations}/costume` },
          { title: 'Finance', url: `/${illustrations}/finance` },
          { title: 'Technology', url: `/${illustrations}/technology` },
          { title: '3d Modeling', url: `/${illustrations}/3d-modeling` },
        ],
      },
    ],
  },
  {
    title: 'Motion Graphics',
    path: `${motionGraphics}`,
    childrens: [
      {
        title: '',
        child: [
          {
            title: 'Background',
            url: `/${motionGraphics}/background`,
          },
          {
            title: 'Overlay Elements',
            url: `/${motionGraphics}/overlay-elements`,
          },
          { title: 'Business', url: `/${motionGraphics}/business` },
          { title: 'Corporate', url: `/${motionGraphics}/corporate` },
          { title: 'Growth', url: `/${motionGraphics}/growth` },
          { title: 'Sales', url: `/${motionGraphics}/sales` },
        ],
      },
    ],
  },
  {
    title: 'Free Templates',
    path: 'free-templates',
    childrens: [
      {
        title: 'After Effect',
        path: 'free-after-effects-templates',
        child: [
          {
            title: 'Slideshows',
            url: `/free-${afterEffectTempalteURL}/slideshow`,
          },
          {
            title: 'Social Media',
            url: `/free-${afterEffectTempalteURL}/social-media`,
          },
          {
            title: 'Logo Animations',
            url: `/free-${afterEffectTempalteURL}/logo-animation`,
          },
          {
            title: 'Opener & Intro',
            url: `/free-${afterEffectTempalteURL}/Opener-&-Intro`,
          },
          {
            title: 'Titles',
            url: `/free-${afterEffectTempalteURL}/titles`,
          },
          {
            title: 'Elements',
            url: `/free-${afterEffectTempalteURL}/elements`,
          },
          {
            title: 'Invitation & Wishes',
            url: `/free-${afterEffectTempalteURL}/invitation-and-wishes`,
          },
        ],
      },
      {
        title: 'Premiere Pro',
        path: 'free-premiere-pro-templates',
        child: [
          { title: 'Intros', url: `/${premierProjectsURL}/intros` },
          {
            title: 'Invitation ',
            url: `/free-${premierProjectsURL}/invitation-and-greeting`,
          },
          {
            title: 'Logo Reveal',
            url: `/free-${premierProjectsURL}/logo-reveal`,
          },
          {
            title: 'Text And Title',
            url: `/free-${premierProjectsURL}/text-and-title`,
          },
          {
            title: 'Video Slideshow',
            url: `/free-${premierProjectsURL}/video-display-slideshow`,
          },
          { title: 'Clean', url: `/free-${premierProjectsURL}/clean` },
        ],
      },
      {
        title: 'Graphics',
        path: 'free-images',
        child: [
          { title: 'cartoon', url: `/free-${graphicsDesign}/cartoon` },
          { title: 'Health', url: `/free-${graphicsDesign}/health` },
          { title: 'Woman', url: `/free-${graphicsDesign}/woman` },
          { title: 'Business', url: `/free-${graphicsDesign}/business` },
          { title: 'Growth', url: `/free-${graphicsDesign}/growth` },
          { title: 'Sales', url: `/free-${graphicsDesign}/sales` },
          { title: 'Investment', url: `/free-${graphicsDesign}/investment` },
        ],
      },
      {
        title: 'Motion Graphics',
        path: 'free-videos',
        child: [
          {
            title: 'Overlay Elements',
            url: `/free-${motionGraphics}/overlay-elements`,
          },
        ],
      },
    ],
  },
];
