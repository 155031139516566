import { Fragment } from 'react';

import Link from 'next/link';

import { Stack } from '@mui/material';

import { SubItems, Title } from './style';

const category = [
  {
    name: 'After Effects',
    href: '/after-effects-templates',
  },
  {
    name: 'Premiere Pro',
    href: '/premiere-pro-templates',
  },
  {
    name: 'Motion Graphics',
    href: '/videos',
  },
];

const Categories = () => {
  return (
    <Stack alignSelf="start" spacing={1}>
      <Title>Categories</Title>
      {category.map((item, index) => (
        <Fragment key={index}>
          <SubItems fontSize={'0.87rem'} fontWeight={400}>
            <Link href={item.href}>{item.name}</Link>
          </SubItems>
        </Fragment>
      ))}
    </Stack>
  );
};

export default Categories;
